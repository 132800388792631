<template>
  <Layout>
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4 class="font-size-18">{{ $t(title) }}</h4>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="float-end d-none d-md-block">
          <router-link to="/supervisors/permissions/add">
            <button class="btn btn-primary">
              {{ $t('Add permission') }}
              <i class="mdi mdi-plus-thick"></i>
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="card-title-desc"></p>
            <div class="table-responsive mb-0">
              <div class="col-md-12 page-loader-parent">
                <div class="page-loader">
                  <md-progress-spinner :md-diameter="50" :md-stroke="5" md-mode="indeterminate"></md-progress-spinner>
                </div>
              </div>
              <div class="col-md-12 d-none2 table-div">
                <md-table v-model="permissions" md-sort="name" md-sort-order="asc" md-card>
                  <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="المعرف" md-numeric>
                      {{ item.id }}
                    </md-table-cell>
                    <md-table-cell md-label="الاسم">
                      {{ item.role_name }}
                    </md-table-cell>
                    <md-table-cell md-label="الوصف">
                        {{ item.role_description }}
                    </md-table-cell>
                    <md-table-cell md-label="الخيارات">
                      <md-menu v-if="permissions_count != 0" md-direction="bottom-start">
                        <i class="mdi mdi-cog-outline font-20" md-menu-trigger></i>
                        <md-menu-content>
                          <md-menu-item :href="'/supervisors/permissions/edit/' + encodeId(item.id)">
                            تعديل
                          </md-menu-item>
                          <md-menu-item @click="deleteCoupon(encodeId(item.id))">
                            <span class="text-danger">حذف</span>
                          </md-menu-item>
                        </md-menu-content>
                      </md-menu>
                    </md-table-cell>
                  </md-table-row>
                </md-table>
                <div v-if="permissions_count == 0" class="rt-noData">لا يوجد بيانات</div>
              </div>
            </div>
            <div v-if="permissions_count != 0" class="row mt-4">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                      @page-click="handlePagination"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import $ from 'jquery';
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
export default {
  page: {
    title: "permissions",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      permissions: [{}, {}, {}, {}, {}, {}, {}, {}],
      permissions_count: 0,
      title: "permissions",
      currentPage: 1,
      rows: null,
      perPage: 10
    };
  },
  mounted() {
    $('.page-loader-parent').show();
    $('.table-div').hide();
    var url = `${this.$appUrl}/api/admin/admin-roles?perPage=${this.perPage}&page=${this.currentPage}`;
    this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
      var res = response.data.roles.data;
      this.rows = response.data.roles.total;
      this.permissions_count = res.length;
      if (res.length > 0) {
        this.permissions = response.data.roles.data;
      }
      $('.page-loader-parent').remove();
      $('.table-div').show();
    });
  },
  methods: {
    encodeId(id) {
      return btoa(id * 987654321);
    },
    deleteCoupon(id) {
      this.$swal.fire({
        title: 'حذف كوبون الخصم',
        text: "هل ترغب بحذف الكوبون؟",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#196dfb',
        cancelButtonColor: '#999999',
        confirmButtonText: 'نعم',
        cancelButtonText: 'إلغاء',
      }).then((result) => {
        if (result.isConfirmed) {
          $('.pre-loader').show();
          var url = `${this.$appUrl}/api/admin/admin-roles/delete/${id}?lang=${this.$i18n.locale}`;
          this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
            if (response.data.status == 1) {
              $('.pre-loader').hide();
              this.$swal.fire({
                title: 'تمت العملية بنجاح',
                text: 'تم حذف الكوبون',
                icon: 'success',
                confirmButtonColor: '#196dfb',
                confirmButtonText: 'الموافقة'
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                } else {
                  window.location.reload();
                }
              });
            } else {
              $('.pre-loader').hide();
              this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                confirmButtonColor: '#196dfb',
                text: response.data.message,
              })
            }
          });
        }
      });
    },
    handlePagination() {
      var url = `${this.$appUrl}/api/admin/admin-roles?page=${this.currentPage}&perPage=${this.perPage}`;
      this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
        this.permissions = response.data.roles.data;
        this.rows = response.data.roles.total;
      });
    },
  }
};
</script>
<style lang="scss">
.md-table.md-theme-default .md-table-row td {
  text-align: center;
}

.md-table.md-theme-default .md-table-head {
  text-align: center;
}

.page-loader {
  position: absolute;
  margin: 0px auto;
  left: 0;
  right: 0;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  z-index: 10;
  text-align: center;

  .md-progress-spinner {
    top: 50%;
  }
}

.page-loader-parent:has(.page-loader) {
  position: relative;
  min-height: 500px;
}

.table-responsive:has(.page-loader-parent) {
  min-height: 350px;
}
</style>